import React from 'react'
import { Helmet } from 'react-helmet'

/* COMPONENTS */
import Layout from '../../components/Layout/index'
import '../diaexpress/diaexpress.scss'

const Banner = () => (
  <>
    <section className="container-fluid p-0 d-md-block d-none">
      <div className="backgroud-banner-diaxpress w-100">
        <div className="container d-flex justify-content-start">
          <div className="banner-info">
          </div>
        </div>
      </div>
    </section>
    <section className="container-fluid p-0 d-md-none d-block">
      <div className="backgroud-banner w-100"></div>
      <div className="container d-flex justify-content-center my-4">
        <div className="banner-info  d-flex flex-column align-items-center m-0 p-0">
        </div>
      </div>
    </section>
  </>
)

const TermosCondicoes = () => {
  const breadC = {
    visible: true,
    color: 'black',
    items: [
      { path: '/', name: 'Home' },
      { path: '/clubdia/termos/', name: 'Termos' }
    ]
  }

  return (
    <Layout breadCrumb={breadC}>
      <Helmet>
        <html lang="pt-BR" amp />
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Termos e Condições do ClubDIA | DIA Supermercados</title>
        <meta name="description" content="Termos e Condições do ClubDIA. Última atualização 28/04/2022." />
        <meta name="keywords" content="termos e condicoes, clubdia" />
        <meta name="author" content="DIA Supermercados" />
      </Helmet>
      <Banner />
      <section className='container'>
        <div className="row">
          <div className="col-12">
            <p className='pt-lg-3 p-0 text-muted'><span className='font-size-12'>ÚLTIMA ATUALIZAÇÃO: 28/04/2022</span></p>
            <h1 className='text-red pt-2 pb-1 '>
              TERMOS E CONDIÇÕES DE USO - CLUB DIA
            </h1>
            <p className='font-size-1'>
            Este site <a href='https://www.dia.com.br/' className='text-red text-underline'>www.dia.com.br</a> (“<b>Site</b>” ou “<b>Plataforma</b>”)
            é operado pelo DIA BRASIL SOCIEDADE LTDA., inscrita no CNPJ nº 03.476.811/0001-51, com sede à Rua Leopoldo de Bulhões, 40, Bairro Vila Clementino,
            na cidade de São Paulo, Estado de São Paulo, CEP: 05425-902 (“<b>DIA</b>”). Em todo o Site, os termos “<b>nós</b>” e “<b>nosso</b>” se referem ao DIA. O DIA oferece este Site,
            incluindo todas as informações, ferramentas e serviços disponíveis para você, o usuário, de acordo com todos os termos, condições, políticas e avisos estabelecidos aqui.<br></br>
            </p>
            <p className='pt-3 font-size-1'>
            Ao fazer uso do nosso Site e de quaisquer ferramentas nele disponibilizada, você estará sujeito aos seguintes Termos e Condições de Uso (“<b>Termos</b>”), incluindo as políticas adicionais mencionados neste documento e/ou disponíveis em nosso Site.<br></br>
            </p>
            <p className='pt-3 pb-4 font-size-1'>
            Os títulos usados nestes Termos são incluídos apenas por conveniência e não limitam ou afetam os direitos e obrigações aqui previstos.
            </p>
          </div>
          <div className="col-12">
            <ol className="title p-0">
              <li className="numberTitle"><span className='font-size-18'>APLICABILIDADE DOS TERMOS</span>
                <ol >
                  <li className='font-size-1'>Esses Termos se aplicam a todos os usuários que acessam o Site.</li>
                  <li className='font-size-1'>Leia estes Termos cuidadosamente antes de utilizar o nosso Site. Ao acessar ou usar qualquer parte do Site, você está sujeito a estes Termos. Se você não concorda com todos os termos e condições aqui previstos, então você deve interromper o uso do Site imediatamente.</li>
                </ol>
              </li>
              <li className="numberTitle"><span className='font-size-18'>DISPONIBILIDADE DA PLATAFORMA, ERROS OU IMPRECISÕES</span>
                <ol>
                  <li className='font-size-1'>O DIA envida seus melhores esforços no sentido de manter as informações e conteúdo do Site sempre atualizados, completos e precisos. Para esse fim, procurará corrigir o mais rápido possível eventuais omissões ou erros contidos no Site.</li>
                  <li className='font-size-1'>O DIA envida seus esforços, também, no que diz respeito à disponibilidade do Site, procurando agir de forma a evitar interrupções e erros na Plataforma. Contudo, o DIA não pode garantir a disponibilização do Site em tempo integral, na medida em que depende de serviços prestados por terceiros, como empresas de telecomunicações e provedores de acesso à internet.</li>
                </ol>
              </li>
              <li className="numberTitle"><span className='font-size-18'>LINKS DE TERCEIROS</span>
                <ol>
                  <li className='font-size-1'>Determinados conteúdos, produtos e serviços disponíveis por meio do nosso Site podem incluir materiais de terceiros. Os links de terceiros podem direcionar você a sites de terceiros que não são afiliados a nós. Não nos responsabilizamos por examinar ou avaliar o conteúdo ou sua precisão.
                    NÃO GARANTIMOS E NEM TEMOS OBRIGAÇÃO OU RESPONSABILIDADE POR QUAISQUER MATERIAIS OU SITES DE TERCEIROS, OU POR QUAISQUER OUTROS MATERIAIS, PRODUTOS OU SERVIÇOS DE TERCEIROS.</li>
                </ol>
              </li>
              <li className="numberTitle"><span className='font-size-18'>PROPRIEDADE INTELECTUAL</span>
                <ol>
                  <li className='font-size-1'>O Site, assim considerando todos os textos, gráficos, imagens, fotos, ilustrações, marcas, denominação social, logotipos, informações, código-fonte, layouts, nomes de domínio, software, know-how, e outros materiais nele contidos são todos protegidos por direitos de propriedade intelectual
                    (“<b>Direitos de Propriedade Intelectual</b>”). Todos os Direitos de Propriedade Intelectual existentes no Site são de titularidade ou foram licenciados ao DIA, de modo que apenas o DIA tem o direito exclusivo de usar ou de qualquer forma explorar os Direitos de Propriedade Intelectual.</li>
                  <li className='font-size-1'>Estes Termos não concedem a você qualquer Direito de Propriedade Intelectual.</li>
                </ol>
              </li>
              <li className="numberTitle"><span className='font-size-18'>USOS PROIBIDOS</span>
                <ol>
                  <li className='font-size-1'>
                    Além de outras proibições estabelecidas nesses Termos, você está proibido de usar o Site ou seu conteúdo para: (a) qualquer finalidade ilícita; (b) solicitar a outros que realizem ou participem de atos ilegais; (c) violar quaisquer regulamentos, regras, leis ou leis locais, internacionais,
                    federais, municipais ou estaduais; (d) infringir ou violar nossos Direitos de Propriedade Intelectual ou os direitos de propriedade intelectual de terceiros; (e) assediar, abusar, insultar, prejudicar, difamar, caluniar, desacreditar, intimidar ou discriminar com base no gênero, orientação sexual,
                    religião, etnia, raça, idade, nacionalidade ou deficiência; (f) apresentar informações falsas ou enganosas; (g) enviar ou transmitir vírus ou qualquer outro tipo de código malicioso, que será ou poderá ser usado de qualquer forma que afete a funcionalidade ou operação do Site ou de qualquer site relacionado,
                    outros sites ou a internet; (h) coletar ou rastrear as informações pessoais de terceiros; (i) prática de spam, phishing, pharm, pretext, spider, crawl ou scrape; (j) qualquer propósito obsceno ou imoral; ou para (k) interferir ou contornar os recursos de segurança do Site ou de qualquer site relacionado.
                  </li>
                </ol>
              </li>
              <li className="numberTitle"><span className='font-size-18'>ISENÇÃO DE GARANTIAS</span>
                <ol>
                  <li className='font-size-1'>O USUÁRIO RECONHECE E CONCORDA QUE A PLATAFORMA É OFERECIDA NO ESTADO EM QUE SE ENCONTRA E TÃO SOMENTE CONFORME DISPONIBILIZADA, RECONHECENDO AINDA, QUE: <b>(i)</b> ASSUME TODOS OS RISCOS RELACIONADOS OU RESULTANTES DO USO DA PLATAFORMA; <b>(ii)</b> NA MÁXIMA EXTENSÃO PERMITIDA PELA LEGISLAÇÃO APLICÁVEL,
                    O DIA SE ISENTA EXPRESSAMENTE DE QUAISQUER GARANTIAS NO SENTIDO DE QUE (<b>a</b>) O SITE SERÁ LIVRE DE ERROS; e (<b>b</b>) O SITE OPERARÁ ININTERRUPTAMENTE E ESTARÁ DISPONÍVEL EM TODO MOMENTO.</li>
                </ol>
              </li>
              <li className="numberTitle"><span className='font-size-18'>INDENIZAÇÃO</span>
                <ol>
                  <li className='font-size-1'>Você concorda em indenizar, defender e isentar o DIA, empresas do mesmo grupo e seus representantes de qualquer reivindicação ou demanda, incluindo honorários advocatícios razoáveis, feitos por terceiros, devido a ou decorrente de sua violação a esses Termos ou sua violação de qualquer lei ou dos direitos de terceiros.</li>
                </ol>
              </li>
              <li className="numberTitle"><span className='font-size-18'>ALTERAÇÕES AOS TERMOS DE USO</span>
                <ol>
                  <li className='font-size-1'>Reservamo-nos o direito, a nosso critério, de atualizar, modificar ou substituir qualquer parte destes Termos ao publicar atualizações e alterações no nosso Site. É sua responsabilidade verificar periodicamente se há alterações em nosso site. Seu acesso ao Site após a publicação de quaisquer alterações a estes Termos implica sua aceitação aos novos termos de uso e/ou de contratação.</li>
                </ol>
              </li>
              <li className="numberTitle"><span className='font-size-18'>DISPOSIÇÕES GERAIS</span>
                <ol>
                  <li className='font-size-1'><u>Nulidade</u>. No caso de qualquer disposição destes Termos ser considerada ilegal, nula ou inexequível, tal disposição será, no entanto, executável na extensão máxima permitida pela lei aplicável, e a parte inexequível será considerada como cortada destes Termos, e tal determinação não afetará a validade e aplicabilidade de quaisquer outras disposições remanescentes.</li>
                  <li className='font-size-1'><u>Acordo integral</u>. Estes Termos e quaisquer políticas publicadas por nós neste Site constituem a totalidade do acordo entre nós sobre o uso/acesso ao Site. Cada aplicativo do DIA será regido por seus próprios termos de uso e política de privacidade.</li>
                  <li className='font-size-1'><u>Sucessão</u>. Estes Termos e a Política de Privacidade obrigam o DIA e o Usuários, seus herdeiros e sucessores a qualquer título.</li>
                  <li className='font-size-1'><u>Renúncia</u>. A tolerância de uma parte para com a outra, relativamente a descumprimento de qualquer das obrigações ora assumidas, não será considerada novação ou renúncia a qualquer direito, constituindo mera liberalidade, que não impedirá a parte tolerante de exigir da outra o fiel cumprimento destes Termos, a qualquer tempo.</li>
                </ol>
              </li>
              <li className="numberTitle"><span className='font-size-18'>LEGISLAÇÃO E FORO APLICÁVEIS</span>
                <ol>
                  <li className='font-size-1'>Estes Termos e quaisquer contratos separados que fornecemos a você, tais como a nossa Política de Privacidade, serão regidos e interpretados de acordo com as leis da República Federativa do Brasil.</li>
                  <li className='font-size-1'>Fica eleito o foro de São Paulo, Estado de São Paulo, para dirimir eventuais controvérsias a respeito de tais documentos e/ou a nossa relação.</li>
                </ol>
              </li>
              <li className='numberTtile'><span className='font-size-18'>INFORMACÕES DE CONTRATO</span>
                <ol>
                  <li className='font-size-1'>Caso suas dúvidas sejam sobre funcionalidades da Plataforma e/ou caso você tenha algum problema técnico ou queira saber sobre o seu pedido, entre em contato por meio dos seguintes canais:</li>
                </ol>
              </li>
            </ol>
            <div className='bg-light px-4 py-lg-3 py-3 mb-5 ContatoEstilo'>
              <div className='row justify-content-between'>
                <div className='col-lg-4 col-12 p-3'>
                  <a className=' w-100 font-size-15' href='https://www.dia.com.br/contato/'>
                    <div className='p-2 bg-success bg-success d-flex justify-content-center ContantoItemEstilo'>
                      <span>Formulário</span>
                    </div>
                  </a>
                </div>
                <div className='col-lg-4 col-12 p-3 '>
                  <a className=' w-100 font-size-15' href='tel:0800 723 1470 '>
                    <div className='p-2 BGred d-flex justify-content-center ContantoItemEstilo'>
                      <span>Canal de voz: 0800 723 1470</span>
                    </div>
                  </a>
                </div>
                <div className='col-lg-4 col-12 p-3'>
                  <a className=' w-100 font-size-15' href='mailto:sac@dia.com.br'>
                    <div className='p-2 BGLightBlue d-flex justify-content-center ContantoItemEstilo'>
                      <span className='text-dark'>E-mail:<a href='mailto:sac@dia.com.br' className='text-danger'> <span>sac@dia.com.br</span></a></span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default TermosCondicoes
